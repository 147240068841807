<template>
  <div style="background-color: #fff; min-height: 100vh">
    <NavBar style="border-bottom: 1px solid #f2f2f2" @navCallback="navCallback">
      <div slot="title">发表评价</div>
    </NavBar>
    <div class="edit-comments">
      <div class="goods-info" v-if="itemInfo.id * 1 > 0">
        <div class="img-box">
          <img :src="itemInfo.goods_img" alt="" />
        </div>
        <span> {{ itemInfo.goods_name }}（{{ itemInfo.item_key }}）</span>
      </div>
      <div class="comments-grade">
        <span class="comments-span1">描述相符合</span>
        <van-rate v-model="value" size="14" gutter="1" />
        <span class="comments-span2" v-if="value * 1 === 5">非常好</span>
        <span class="comments-span2" v-else-if="value * 1 >= 3">中评</span>
        <span class="comments-span2" v-else>差评</span>
      </div>
      <div>
        <van-field
          v-model="message"
          rows="2"
          autosize
          left-icon="edit"
          type="textarea"
          maxlength="100"
          placeholder="从多个角度评价宝贝，可以帮助更多想买的人"
          show-word-limit
        />
      </div>
      <div>
        <van-uploader
          v-model="fileList"
          multiple
          :after-read="commonBase64"
          :max-count="3"
        />
      </div>
      <div class="publish-button">
        <van-button type="danger" round block @click="setComment()">
          发表
        </van-button>
      </div>
    </div>
    <!-- 提示弹出层 -->
    <van-popup v-model="showPopup" round>
      <div class="popup-box">
        <div class="popup-title">
          <h3>确认要退出评价？</h3>
          <p>退出后前编辑过内容将不保存</p>
        </div>
        <div class="button-box">
          <div class="button">
            <van-button type="danger" round plain block @click="isQuit(false)">
              取消
            </van-button>
          </div>
          <div class="button">
            <van-button type="danger" round block @click="isQuit(true)">
              确认
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 评价发表成功后显示 PublishSucceed -->
    <PublishSucceed v-if="false"></PublishSucceed>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar.vue";
import PublishSucceed from "./publish-succeed";
import { commonBase64 } from "@/api/common";
import { getRemainInfo, setComment } from "@/api/goods";
import { Toast } from "vant";

export default {
  name: "publish-evaluate",
  components: {
    NavBar,
    PublishSucceed,
  },
  data() {
    return {
      itemId:
        this.$route.query.itemId === undefined ? 0 : this.$route.query.itemId,
      showPopup: false,
      itemInfo: { id: 0 },
      value: 5,
      message: "",
      fileList: [],
      isAjax: false,
    };
  },
  mounted() {
    this.getRemainInfo();
  },
  methods: {
    navCallback() {
      this.showPopup = true;
    },
    isQuit(bool) {
      if (bool) {
        // 确认的时候需要做什么
        this.value = 5;
        this.message = "";
      }
      this.showPopup = false;
    },
    async getRemainInfo() {
      const ret = await getRemainInfo({ item_id: this.itemId });
      if (ret.code * 1 == 1) {
        this.itemInfo = ret.data;
      } else {
        Toast.fail("评价商品信息错误");
      }
    },
    async commonBase64(file) {
      //图片上传
      file.status = "uploading";
      file.message = "上传中...";
      const ret = await commonBase64({ image: file.content });
      if (ret.code * 1 == 1) {
        file.status = "";
        file.message = "";
        file.url = ret.data.url;
      } else {
        file.status = "failed";
        file.message = "上传失败";
      }
    },
    async setComment() {
      let imgs = [];
      for (let i = 0; i < this.fileList.length; i++) {
        imgs[i] = this.fileList[i].url;
        if (this.fileList[i].status !== "") {
          Toast.fail(this.fileList[i].message);
          return false;
        }
      }
      let imgsStr = imgs.join(";");
      // return  false;
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      // console.log(
      //   this.itemInfo.goods_id,
      //   this.itemInfo.id,
      //   this.message,
      //   this.value,
      //   imgsStr
      // );
      const ret = await setComment({
        goods_id: this.itemInfo.goods_id,
        item_id: this.itemInfo.id,
        content: this.message,
        grade: this.value,
        goods_imgs: imgsStr,
      });
      this.isAjax = false;
      if (ret.code * 1 == 1) {
        //定时跳转
        this.$router.push({ name: "publish-succeed" });
        setTimeout(function () {}, 1500);
      } else {
        Toast.fail(ret.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-cell {
  padding: 10px 0;
}
.edit-comments {
  padding: 64px 26px 26px 26px;

  .goods-info {
    display: flex;
    span {
      font-weight: 500;
      font-size: 17px;
    }
    .img-box {
      width: 58px;
      margin-right: 10px;

      img {
        width: 58px;
        height: 58px;
        display: block;
        border-radius: 6px;
      }
    }
  }

  .comments-grade {
    display: flex;
    align-items: center;
    margin: 18px 0;

    .comments-span1 {
      color: #aaa;
      font-size: 17px;
      margin-right: 12px;
    }

    .comments-span2 {
      margin-left: 10px;
      font-size: 17px;
    }
  }

  .publish-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
  }
}

// 提示弹出层
.popup-box {
  width: 80vw;
  padding: 12px;

  .popup-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-box {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

    .button {
      width: 116px;
    }
  }
}
</style>
